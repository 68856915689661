import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { IElementType } from "@faro-lotv/ielement-types";
import { isCaptureTreeScanEntity } from "@pages/project-details/project-data-management/raw-scans/raw-scans-utils";
import {
  fetchAllRegistrationRevisions,
  fetchCaptureTreeForMainRevision,
} from "@store/capture-tree/capture-tree-thunks";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { useAppDispatch } from "@store/store-helper";
import { useCallback, useEffect } from "react";
import { useToast } from "@hooks/use-toast";
import { usePolling } from "@hooks/use-polling";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { fetchCloudRegistrationTasks } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { resetSdbBackgroundTasksState } from "@store/sdb-background-tasks/sdb-background-tasks-slice";

/**
 * Custom hook that polls the capture tree data
 */
export function useCaptureTree({ projectId }: BaseProjectIdProps): void {
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const progressApiClient = useProgressApiClient({
    projectId: projectId.toString(),
  });

  /**
   * Fetches:
   * - The registration revisions of the current project
   * - The capture tree entities for the main revision of the current project
   * - The scan ielements and their children PointCloudStream ielement from the BI tree
   * - The ProgressAPI tasks of type "CloudRegistration"
   * The presence of the PointCloudStream as children of the scan indicates that the scan has been processed.
   *
   * @throws {error} if the requests to fetch capture tree data fail.
   */
  const fetchCaptureTreeData = useCallback(async (): Promise<void> => {
    const revisionsPromise = dispatch(
      fetchAllRegistrationRevisions({ projectApiClient })
    ).unwrap();
    const entitiesPromise = dispatch(
      fetchCaptureTreeForMainRevision({ projectApiClient })
    ).unwrap();
    const cloudRegistrationTasksPromise = dispatch(
      fetchCloudRegistrationTasks({ progressApiClient })
    ).unwrap();

    // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
    const [_, entities, __] = await Promise.all([
      revisionsPromise,
      entitiesPromise,
      cloudRegistrationTasksPromise,
    ]);

    const scanEntities = entities.filter(isCaptureTreeScanEntity);
    const scanEntitiesIds = scanEntities.map((entity) => entity.id);

    await dispatch(
      fetchProjectIElements({
        fetcher: async () => {
          const rootElementPromise = projectApiClient.getRootIElement();

          const scanElementsPromise = projectApiClient.getAllIElements({
            ids: scanEntitiesIds,
          });

          const pointCloudStreamElementsPromise =
            projectApiClient.getAllIElements({
              types: [IElementType.pointCloudStream],
              ancestorIds: scanEntitiesIds,
            });

          const [rootElement, scanElements, pointCloudStreamElements] =
            await Promise.all([
              rootElementPromise,
              scanElementsPromise,
              pointCloudStreamElementsPromise,
            ]);

          return [rootElement, ...scanElements, ...pointCloudStreamElements];
        },
      })
    ).unwrap();
  }, [dispatch, progressApiClient, projectApiClient]);

  /**
   * Error handler callback.
   */
  const errorHandler = useCallback((): void => {
    showToast({
      message: "Failed to fetch capture tree data of the project",
      type: "error",
    });
  }, [showToast]);

  usePolling({
    callback: fetchCaptureTreeData,
    errorHandler,
    maxAttemptsMsg:
      "Unable to fetch capture tree data after multiple attempts. Please reload the application to try again.",
  });

  /**
   * Resets the background tasks store on component unmount.
   * This is done to avoid any potential problem with the Project Activity tab, that tab shows all background tasks
   * and since we already fetched some tasks it might interfere with how pagination works in Activity tab.
   */
  useEffect(() => {
    return () => {
      dispatch(resetSdbBackgroundTasksState());
    };
  }, [dispatch]);
}
