import {
  BackgroundTaskStates,
  BackgroundTaskStatusType,
  BackgroundTaskType,
  CadTaskType,
  PointCloudTaskType,
  VideoModeTaskType,
  WSMigrationTaskType,
} from "@api/progress-api/progress-api-types";

export const POINT_CLOUD_TASK_TYPES: { [key in PointCloudTaskType]: key } = {
  /** PointCloud processing task */
  PointCloudToPotree: "PointCloudToPotree",

  /** PointCloud processing task. It will be deprecated */
  PointCloudLazToPotree: "PointCloudLazToPotree",

  /** Deprecated. But it's better to keep it in case there is project with a task of this type */
  PointCloudE57ToLaz: "PointCloudE57ToLaz",

  /** GeoSlam processing task */
  PointCloudGSToLaz: "PointCloudGSToLaz",

  /** MeshGenerator task */
  GenerateStreamableMesh: "GenerateStreamableMesh",

  /** PointCloud exporting task (Exporting a bounding box, so a subset of a full target point cloud) */
  PointCloudExport: "PointCloudExport",

  /** Cloud registration task. Registration of 2 points clouds */
  C2CRegistration: "C2CRegistration",

  RegisterMultiCloudDataSet: "RegisterMultiCloudDataSet",

  /** Used to process a single Focus raw scan */
  ProcessPointCloudFlsRaw: "ProcessPointCloudFlsRaw",

  /** Converts an entire processed and registered DataSetFocus to a single E57 file */
  ConvertFocusDataSet: "ConvertFocusDataSet",

  /** Registers an entire processed DataSetFocus */
  RegisterFocusDataSet: "RegisterFocusDataSet",

  /** Cloud registration task using CaptureTree */
  CloudRegistration: "CloudRegistration",
};

export const VIDEO_MODE_TASK_TYPES: { [key in VideoModeTaskType]: key } = {
  /** VideoMode processing task */
  VideoMode: "VideoMode",
};

export const CAD_TASK_TYPES: { [key in CadTaskType]: key } = {
  /** CAD import task */
  BimModelImport: "BimModelImport",
};

export const WS_MIGRATION_TASK_TYPES: { [key in WSMigrationTaskType]: key } = {
  Img360Slicing: "Img360Slicing",
  ImgSheetStitching: "ImgSheetStitching",
};

export const BACKGROUND_TASK_TYPES: { [key in BackgroundTaskType]: key } = {
  ...POINT_CLOUD_TASK_TYPES,
  ...VIDEO_MODE_TASK_TYPES,
  ...CAD_TASK_TYPES,
  ...WS_MIGRATION_TASK_TYPES,
};

export const BACKGROUND_TASK_STATUS_TYPE: {
  [key in BackgroundTaskStatusType]: key;
} = {
  State: "State",
  Progress: "Progress",
};

export const BACKGROUND_TASK_STATES: { [key in BackgroundTaskStates]: key } = {
  /** A task has been created */
  Created: "Created",

  /** A task has been scheduled */
  Scheduled: "Scheduled",

  /** The task is inprogress */
  Started: "Started",

  /** The task has successfully completed */
  Succeeded: "Succeeded",

  /** The task has been canceled by the user */
  Aborted: "Aborted",

  /** The task has failed */
  Failed: "Failed",
};
