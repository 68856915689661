import {
  BackgroundTask,
  BackgroundTaskStates,
} from "@api/progress-api/progress-api-types";

/** Possible states of an SdbBackgroundTask entity */
type SdbBackgroundTaskStates = BackgroundTaskStates | "Pending";

export type SdbBackgroundTask = Pick<
  BackgroundTask,
  "id" | "createdAt" | "taskType" | "context"
> & {
  /** The background task category */
  taskCategory: BackgroundTaskCategory | null;

  /** Possible state values for a background task in the Progress API */
  status: SdbBackgroundTaskStates | null;

  /** Value that represents the completion of a task */
  progress?: string;

  /** ISO-8601 date-time string when the update happened */
  updatedAt: string | null;

  /** Additional info coming from the backend to show to the user */
  message?: string;

  /** A human readable name for the associated IElement */
  elementName?: string;

  /** Deep link to open the associated IElement in the Sphere Viewer */
  viewerDeepLink?: string;
};

export enum BackgroundTaskCategory {
  /** Point cloud background task */
  pointCloud = "PointCloud",

  /** Video mode background task */
  videoMode = "VideoMode",

  /** CAD background task */
  cad = "Cad",
}

/** Base props for a sdb background task entity */
export interface BaseBackgroundTaskProps {
  /** The SDB background task */
  task: SdbBackgroundTask;
}
