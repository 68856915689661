import { RegistrationRevision } from "@faro-lotv/service-wires";
import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";

/** List of available types for the project data management tab */
export enum ProjectDataManagementType {
  rawData = "rawData",
  preparedData = "preparedData",
  publishedData = "publishedData",
}

/** Registration revision entity with additional information about its associated ProgressAPI task */
export interface SdbRegistration extends RegistrationRevision {
  /** ProgressAPI task associated to the registration revision */
  task?: SdbBackgroundTask;
}
