import { FaroChipProps } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";
import { sphereColors } from "@styles/common-colors";

/** Human readable text for each registration revision state */
export const REGISTRATION_STATE_LABEL: { [key in RegistrationState]: string } =
  {
    [RegistrationState.started]: "Scheduled",
    [RegistrationState.cloudRegistrationStarted]: "Registering",
    [RegistrationState.registered]: "Registered",
    [RegistrationState.merged]: "Merged",
    [RegistrationState.canceled]: "Canceled",
  };

type ColorProps = Required<
  Pick<FaroChipProps, "color" | "backgroundColor" | "borderColor">
>;

/** Colors to use in the chip component for each state of the registration revisions */
export const REGISTRATION_STATE_COLORS: {
  [key in RegistrationState]: ColorProps;
} = {
  [RegistrationState.started]: {
    color: sphereColors.gray800,
    backgroundColor: sphereColors.pureWhite,
    borderColor: sphereColors.gray200,
  },
  [RegistrationState.cloudRegistrationStarted]: {
    color: sphereColors.yellow600,
    backgroundColor: sphereColors.yellow100,
    borderColor: sphereColors.yellow200,
  },
  [RegistrationState.registered]: {
    color: sphereColors.green600,
    backgroundColor: sphereColors.green50,
    borderColor: sphereColors.green100,
  },
  [RegistrationState.merged]: {
    color: sphereColors.green600,
    backgroundColor: sphereColors.green50,
    borderColor: sphereColors.green100,
  },
  [RegistrationState.canceled]: {
    color: sphereColors.gray800,
    backgroundColor: sphereColors.pureWhite,
    borderColor: sphereColors.gray200,
  },
};
