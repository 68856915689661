import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProjectIElements } from "@store/i-elements/i-elements-slice";
import { AppDispatch, RootState } from "@store/store-helper";
import {
  BaseProgressApiClient,
  BaseProjectApiClientProps,
} from "@store/store-types";
import {
  generateSdbBackgroundTasks,
  getTasksIElementIds,
} from "@utils/sdb-background-tasks-utils";
import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { ProgressApiLatestStatusResponse } from "@api/progress-api/progress-api-types";

interface UpdateBackgroundTasksProps
  extends BaseProjectApiClientProps,
    BaseProgressApiClient {
  /** The page token to fetch the tasks before the last fetched page */
  beforePage: string | null;

  /**
   * Whether the before page token should be overridden with a
   * new value after updating tasks, if there already is one present
   */
  shouldOverrideBeforePage?: boolean;
}

interface UpdateBackgroundTasksResult
  extends Pick<UpdateBackgroundTasksProps, "shouldOverrideBeforePage">,
    Pick<ProgressApiLatestStatusResponse, "before" | "after" | "totalCount"> {
  /** List of background tasks */
  sdbBackgroundTasks: SdbBackgroundTask[];
}

export const updateBackgroundTasks = createAsyncThunk<
  UpdateBackgroundTasksResult,
  UpdateBackgroundTasksProps,
  {
    state: RootState;
    dispatch: AppDispatch;
  }
>(
  "sdbBackgroundTasks/updateBackgroundTasks",
  async (
    {
      progressApiClient,
      projectApiClient,
      beforePage,
      shouldOverrideBeforePage = true,
    },
    { dispatch }
  ) => {
    try {
      const { data, totalCount, before, after } =
        await progressApiClient.requestProgress({
          before: beforePage,
        });

      if (data) {
        const iElementsIds = getTasksIElementIds(data);

        // If the iElementsIds length is zero then all project iElements will be fetched
        // This is not desired since projects can typically have thousands of iElements and fetching them
        // each minute it's demanding for the SDB and the backend.
        if (iElementsIds.length > 0) {
          // Fetch IElements associated with the fetched tasks
          await dispatch(
            fetchProjectIElements({
              fetcher: async () => {
                const elements = await projectApiClient.getAllIElements({
                  descendantIds: iElementsIds,
                });
                return elements;
              },
            })
          ).unwrap();
        }

        const sdbBackgroundTasks = generateSdbBackgroundTasks(data);

        return {
          sdbBackgroundTasks,
          totalCount,
          before,
          after,
          shouldOverrideBeforePage,
        };
      }

      return {
        sdbBackgroundTasks: [],
        totalCount,
        before,
        after,
        shouldOverrideBeforePage,
      };
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);

/** Fetches tasks of type "CloudRegistration" */
export const fetchCloudRegistrationTasks = createAsyncThunk<
  SdbBackgroundTask[],
  BaseProgressApiClient
>(
  "sdbBackgroundTasks/fetchCloudRegistrationTasks",
  async ({ progressApiClient }) => {
    try {
      const { data } = await progressApiClient.requestProgress({
        before: null,
        taskType: "CloudRegistration",
      });

      if (!data) {
        return [];
      }

      return generateSdbBackgroundTasks(data);
    } catch (error) {
      throw new Error(getErrorDisplayMarkup(error));
    }
  }
);
