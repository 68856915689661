import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { SdbRegistration } from "@custom-types/project-data-management-types";
import { Box, Typography } from "@mui/material";
import {
  getInspectAndPublishToolUrl,
  isRegisteredRegistrationRevision,
} from "@pages/project-details/project-data-management/prepared-data/prepared-data-utils";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";

interface Props {
  /** Registration */
  registration: SdbRegistration;
}

/** Renders prepared data actions column */
export function PreparedDataActions({
  registration,
}: Props): JSX.Element | null {
  const { trackEvent } = useTrackEvent();
  const { projectId } = useDataManagementContext();

  if (isRegisteredRegistrationRevision(registration)) {
    return (
      <Box
        data-testid="prepared-data-actions-container"
        sx={{
          visibility: "hidden",
          cursor: "pointer",
          ...withEllipsis,
        }}
        className={SHOW_ON_HOVER_CLASS}
        onClick={() => {
          const url = getInspectAndPublishToolUrl(projectId, registration.id);

          trackEvent({
            name: DataManagementEvents.openDataManagementInspectPublish,
            props: {
              projectId: projectId,
              registerId: registration.id,
            },
          });

          window.location.href = url;
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            ...withEllipsis,
            color: sphereColors.blue500,
            fontWeight: "600",
          }}
        >
          Inspect & publish
        </Typography>
      </Box>
    );
  }

  return null;
}
